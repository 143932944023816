.cardPhraseTheme {
	background-color: var(--color-light);
	border-radius: 8px;
	padding: 24px;
	border: 2px solid var(--color-light);
	font-size: 24px;
	font-weight: bold;
	cursor: pointer;
	display: flex;
	transition: border-color .2s ease;
	width: 100%;
	height: 150px;
	flex-direction: column;
	justify-content: space-between;
	text-align: left;
}

.cardPhraseTheme:hover {
	border-color: var(--color-primary);
}
