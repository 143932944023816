.button {
	border-radius: 8px;
	padding: 10px 16px;
	color: var(--color-light);
	border: none;
	font-size: 16px;
	font-weight: bold;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	transition: transform .2s ease, box-shadow .2s ease;
	box-shadow: 0 0 0 rgba(0, 0, 0, 0.09);
}

.button:hover:not(:active) {
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.09);
	transform: translateY(-4px);
}
